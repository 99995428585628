<template>
  <v-card>
    <v-card-title class="text-h5" primary-title>
      <h6 class="text-h6">
        {{ $trans("add_new_stripe_product_price_title") }}
      </h6>
    </v-card-title>
    <v-divider />
    <v-form ref="StripeProductPrice" @submit.prevent="handleAdd">
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="unitAmount"
                :disabled="isLoading"
                type="number"
                hide-details="auto"
                validate-on-blur
                outlined
                :rules="[
                  rules.required,
                  rules.maxNumber,
                  rules.minNumber(unitAmount, minimalCurrencyValue),
                ]"
                class="required-star"
                :label="
                  $trans('price_in_currency', {
                    currency: $config('currency').toUpperCase(),
                  })
                "
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-checkbox
                v-model="isRecurring"
                :disabled="isLoading"
                hide-details
                :label="$trans('subscription_recurring_title')"
              />
            </v-col>
          </v-row>
          <v-row v-if="isRecurring">
            <v-col class="d-flex align-center">
              <div class="text-no-wrap mr-2 checkbox-intro">
                {{ $trans("recurrent_each") }}
              </div>
              <v-text-field
                v-model="intervalCount"
                :rules="[
                  rules.required,
                  rules.maxNumber,
                  rules.minNumber(intervalCount, 1),
                ]"
                outlined
                hide-details
                type="number"
                dense
                class="small-width-input"
              />
              <v-select
                v-model="recurring"
                :rules="[rules.required]"
                class="ml-2"
                hide-details
                :items="recurringFrequencies"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text--secondary">
              {{ $trans("fields_required") }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="light" text :disabled="isLoading" @click="$emit('close')">
          {{ $trans("cancel") }}
        </v-btn>
        <v-btn :disabled="isLoading" color="primary" text type="submit">
          {{ $trans("add") }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import {
  maxNumber,
  required,
  minNumber,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import recurringFrequencies from "@/calendesk/prototypes/recurringFrequencies";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "AddStripeProductDialog",
  mixins: [sharedActions],
  data() {
    return {
      recurringFrequencies,
      isLoading: false,
      unitAmount: null,
      recurring: null,
      isRecurring: false,
      intervalCount: 1,
      rules: {
        required,
        maxNumber,
        minNumber,
      },
    };
  },
  computed: {
    minimalCurrencyValue() {
      if (
        this.$config("currency") &&
        this.$config("currency").toLowerCase() !== "pln"
      ) {
        return 1;
      }

      return 2;
    },
  },
  methods: {
    ...mapActions({
      createStripeProductPrice: "stripe/createStripeProductPrice",
      fetchConnectProducts: "stripe/fetchConnectProducts",
    }),
    handleAdd() {
      if (this.$refs.StripeProductPrice.validate()) {
        this.add();
      }
    },
    async add() {
      this.isLoading = true;
      this.$emit("loading", true);
      try {
        const data = {
          product_id: this.getConfirmDialog.data.product.id,
          unit_amount: Math.round(this.unitAmount * 100),
        };

        if (this.recurring) {
          data.recurring = this.recurring;
          data.interval_count = this.intervalCount;
        }

        await this.createStripeProductPrice(data);
        await this.fetchConnectProducts({ limit: 9999 });
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.isLoading = false;
        this.$emit("loading", false);
        this.$emit("close");
      }
    },
  },
};
</script>
