var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"text-h5",attrs:{"primary-title":""}},[_c('h6',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$trans("add_new_stripe_product_price_title"))+" ")])]),_c('v-divider'),_c('v-form',{ref:"StripeProductPrice",on:{"submit":function($event){$event.preventDefault();return _vm.handleAdd.apply(null, arguments)}}},[_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"required-star",attrs:{"disabled":_vm.isLoading,"type":"number","hide-details":"auto","validate-on-blur":"","outlined":"","rules":[
                _vm.rules.required,
                _vm.rules.maxNumber,
                _vm.rules.minNumber(_vm.unitAmount, _vm.minimalCurrencyValue) ],"label":_vm.$trans('price_in_currency', {
                  currency: _vm.$config('currency').toUpperCase(),
                })},model:{value:(_vm.unitAmount),callback:function ($$v) {_vm.unitAmount=$$v},expression:"unitAmount"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-checkbox',{attrs:{"disabled":_vm.isLoading,"hide-details":"","label":_vm.$trans('subscription_recurring_title')},model:{value:(_vm.isRecurring),callback:function ($$v) {_vm.isRecurring=$$v},expression:"isRecurring"}})],1)],1),(_vm.isRecurring)?_c('v-row',[_c('v-col',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-no-wrap mr-2 checkbox-intro"},[_vm._v(" "+_vm._s(_vm.$trans("recurrent_each"))+" ")]),_c('v-text-field',{staticClass:"small-width-input",attrs:{"rules":[
                _vm.rules.required,
                _vm.rules.maxNumber,
                _vm.rules.minNumber(_vm.intervalCount, 1) ],"outlined":"","hide-details":"","type":"number","dense":""},model:{value:(_vm.intervalCount),callback:function ($$v) {_vm.intervalCount=$$v},expression:"intervalCount"}}),_c('v-select',{staticClass:"ml-2",attrs:{"rules":[_vm.rules.required],"hide-details":"","items":_vm.recurringFrequencies,"outlined":"","dense":""},model:{value:(_vm.recurring),callback:function ($$v) {_vm.recurring=$$v},expression:"recurring"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$trans("fields_required"))+" ")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"light","text":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$trans("cancel"))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.isLoading,"color":"primary","text":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$trans("add"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }